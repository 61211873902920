import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { RouteHandlerService } from './services/route-handler-service';

export type SupportedLanguage = 'en' | 'sk'

@Component({
    selector: 'Workspace-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    constructor(
        private translate: TranslateService, private router: Router, private titleService: Title, private route: ActivatedRoute, private rh: RouteHandlerService) { }
    langStorageKey: string = 'langPref';
    initialLoaded: boolean = false;
    ngOnInit(): void {
        this.translate.addLangs(['en', 'sk']);
        this.translate.setDefaultLang('en');

        this.router.events.subscribe(e => {
            // dynamically assign title
            if (e instanceof NavigationEnd) {
                const title = this.route.firstChild && this.route.firstChild.snapshot.data['title'];
                this.titleService.setTitle(`My Drop Matters ${title ? `| ${title}` : ''}`);
            }
        });

        this.rh.activeURL.subscribe(url => {
            if (this.initialLoaded) return;
            if (!url) return;
            this.initialLoaded = true;
            const tldLang = url.split('.').reverse()[0].split('/')[0] === 'sk' ? 'sk' : 'com';
            const firstSegment = url.split('.').reverse()[0].split('/')[1];
            if (!tldLang) {
                // undefined tld
                localStorage.setItem(this.langStorageKey, 'en');
                this.translate.use('en');
            }
            switch (tldLang) {
                case "com":
                    localStorage.setItem(this.langStorageKey, 'en');
                    this.translate.use('en');
                    // en or sk
                    if (firstSegment && firstSegment.length === 2 && firstSegment === 'sk') {
                        if (environment.production) window.location.href = 'https://mydropmatters.sk';
                        else window.location.href = 'https://dev.mydropmatters.sk';
                    }
                    else if (firstSegment && firstSegment.length === 2) { this.router.navigate(['/']); }
                    break;
                case "sk":
                    localStorage.setItem(this.langStorageKey, 'sk');
                    this.translate.use('sk');
                    // en or sk
                    if (firstSegment && firstSegment.length === 2 && firstSegment === 'en') {
                        if (environment.production) window.location.href = 'https://mydropmatters.com';
                        else window.location.href = 'https://dev.mydropmatters.com';
                    }
                    else if (firstSegment && firstSegment.length === 2) { this.router.navigate(['/']); }
                    break;
                default:
                    localStorage.setItem(this.langStorageKey, 'en');
                    this.translate.use('en');
                    break;
            }
        });

        // disabled user selection (live only)
        if (environment.production) {
            document.body.classList.add('select-none');
            document.addEventListener('selectstart', (e) => e.preventDefault());
            document.addEventListener('contextmenu', (e) => e.preventDefault());
        }
    }
}
