import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'not-found',
    templateUrl: './not-found.page.html',
    styleUrls: ['./not-found.page.scss'],
})
export class NotFoundPageComponent implements OnInit {
    constructor(private router: Router) {}
    ngOnInit(): void {
        document.body.style.overflow = 'hidden';
    }
    goHome() {
        this.router.navigate(['/']);
        document.body.style.overflow = 'auto';
    }
}
