import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Language } from '../../components/navbar/navbar.component';
@Component({
    selector: 'home',
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],
})
export class HomePageComponent implements OnInit {
    constructor(private translate: TranslateService) { }
    gridPartIcons: string[] = [
        'Activity_icon.svg',
        'Bowling_icon.svg',
        'Message_icon.svg',
        'www_icon.svg',
    ];
    // modal
    isModalOpen: boolean = false;
    language: Language = this.translate.currentLang as Language;
    // slick carousel
    @ViewChild('carousel', { static: true }) carousel: SlickCarouselComponent;
    slides: number[] = [0, 1, 2, 3, 4];
    slideCurrent: number = 0;
    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: false,
        arrows: false,
    };
    // logo carousel
    logoSlideConfig = {
        slideToScroll: 1,
        variableWidth: true,
        adaptiveHeight: true,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 10,
        speed: 4000,
        useCSS: false,
        centerMode: true,
    }


    toggleModal() {
        if (!this.isModalOpen) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        this.isModalOpen = !this.isModalOpen;
    }
    scrollToDownload(e: Event) {
        e.preventDefault();
        const target = document.getElementById('download');
        if (target) target.scrollIntoView();
    }
    ngOnInit(): void {

        this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
            const lang = e.lang;
            if (lang) this.language = e.lang as Language;
        });

        this.carousel.afterChange.subscribe((slick) => {
            this.slideCurrent = slick.currentSlide;
        });
    }

    slidePrev() {
        if (this.slideCurrent > 0) {
            this.carousel.slickPrev();
        } else {
            this.carousel.slickGoTo(this.slides.length);
        }
    }
    slideNext() {
        if (
            this.slideCurrent <
            this.slides.length - this.slideConfig.slidesToShow
        ) {
            this.carousel.slickNext();
        } else {
            this.carousel.slickGoTo(0);
        }
    }


    handleAnimationState(target: HTMLElement, state: 'paused' | 'unpaused') {
        for (let i = 0; i < target.children.length; i++) {
            const anchor = target.children[i];
            anchor.classList.remove(state === 'paused' ? 'unpaused' : 'paused');
            anchor.classList.add(state ?? 'paused');
        }
    }

    pauseAnimation(e: Event) {
        const target = e.target as HTMLElement;
        const isImage = target instanceof HTMLImageElement;
        if (isImage) {
            const parent = target.parentElement.parentElement;
            if (parent) this.handleAnimationState(parent, 'paused');
        } else {
            this.handleAnimationState(target, 'paused');
        }
    }
    unPauseAnimation(e: Event) {
        const target = e.target as HTMLElement;
        const isImage = target instanceof HTMLImageElement;
        if (isImage) {
            const parent = target.parentElement.parentElement;
            if (parent) this.handleAnimationState(parent, 'unpaused');
        } else {
            this.handleAnimationState(target, 'unpaused');
        }
    }
    // reagain animation (mobile only focus)
    @HostListener("document:visibilitychange", ["$event"]) relaunchAnimation() {
        const pausedEls = document.querySelectorAll('.paused');
        if (!pausedEls) return;

        for (let i = 0; i < pausedEls.length; i++) {
            const pausedEl = pausedEls[i];
            pausedEl.classList.remove('paused');
        }
    }
}
