<c-navbar></c-navbar>
<section class="s-preskoly">
    <!-- each layout element contained within 1440px -->
    <img class="s-preskoly-layout--upperwave" src="assets/images/preskoly/Wave_pink_BG.svg" alt="Pink background" />

    <div class="s-preskoly-layout">
        <img class="s-preskoly-layout--mascot" src="assets/images/preskoly/Drop_illustration.svg"
            alt="Droplet mascot" />
        <img class="s-preskoly-layout--vdrop" src="assets/images/preskoly/vertical_drop.svg"
            alt="vertical purple drop" />
    </div>

    <div class="s-preskoly-content">
        <div class="s-preskoly-inner">
            <h1 class="s-preskoly__heading">
                Staňte sa superhrdinami pre vaše deti, darujte krv!
            </h1>

            <div class="s-preskoly__intro">
                <p>
                    Medicína 21. storočia dokáže úžasné veci. Transplantovať
                    srdce, udržať pri živote dieťa s pôrodnou váhou 245 gramov
                    či nahradiť časti kostí implantátmi z 3D tlačiarne. Aby však
                    lekári mohli prostredníctvom výdobytkov medicíny zachraňovať
                    ľudské životy, potrebujú zdanlivo banálnu vec. Tekutinu,
                    ktorú napriek pokroku nedokážeme ničím nahradiť - ľudskú
                    krv. Krv sa v nemocniciach používa nepretržite. Na Slovensku
                    potrebuje za 1 hodinu transfúzny prípravok v priemere 23
                    pacientov. Okrem obetí úrazov a nehôd ju pravidelne
                    potrebujú predčasne narodené deti, ľudia podstupujúci
                    operácie, pacienti liečiaci sa na leukémiu, nádorové
                    ochorenia, ochorenia krvných buniek a mnohí ďalší. Jednotka
                    transfúznej krvi s objemom 450 ml môže zachrániť až 3 ľudské
                    životy.
                </p>
                <p>
                    Preto každý, kto sa rozhodne byť darcom, sa stáva
                    superhrdinom v pravom slova zmysle. Obrovskú hodnotu
                    darcovstva si však mnohí neuvedomujeme, pokiaľ vážne
                    neochorieme my sami alebo niekto blízky. Takúto
                    nevyspytateľnosť života zažila aj rodina Gabriela, ktorý je
                    žiakom jednej z bratislavských škôl. Toto je Gabkov príbeh,
                    ale určite aj vaša škola alebo organizácia má ten svoj, pre
                    ktorý sa oplatí ísť darovať krv a zachrániť tak život.
                </p>
            </div>

            <img class="s-preskoly__quotation" src="assets/images/preskoly/Quotation_icon.svg" alt="quotation icon" />
            <article class="s-preskoly-gabko">
                <p>Ahojte, volám sa Gabko a mám už 6 rokov.</p>
                <p>
                    Narodeniny som oslávil v marci, síce v nemocnici, ale vďaka niekomu, kto bol
                    darovať krv. Nový rok mi nezačal najlepšie, zistili mi
                    akútnu lymfoblastickú leukémiu. Pani doktorka mi objednala
                    odvoz s majákom. Pri tejto chorobe mi kostná dreň netvorila
                    krv a krvné doštičky. Už teda viem, prečo som mal toľko
                    modrín a nevládal som chodiť. Hneď po príchode ma privítali
                    dávkou krvi a dávkou doštičiek. Zabralo to. Hneď mi bolo
                    lepšie.<br /><br />
                    Z nemocnice nemám strach, poznám to tam. Keď som bol malý,
                    ujo doktor mi v detskom kardiocentre prišil veľkú záplatu na
                    srdiečko. Páčilo sa mi tam, a odvtedy aj rád zašívam
                    súrodencom dierky na ponožkách.<br /><br />
                    Ujo kardiológ mi povedal, že moje srdiečko bude za mňa silno
                    bojovať a zvládne to. Prvý mesiac som mal sám izbu a mamina
                    sa tešila, že nemusí variť.<br />
                    Už je október, kamoši na mňa čakajú v škole. V pondelok som
                    dostal poslednú transfúziu. Chemoterapie mám za sebou a
                    podľa pani doktorky už moja kostná dreň je opravená a bude
                    sama tvoriť krvinky. Mám tu veľa kamošov, ktorých ešte
                    opravujú.<br />
                    <br />
                    Ďakujem všetkým darcom, že si môžem baliť školskú tašku.<br />
                    Keď budem veľký, tiež budem pomáhať.
                </p>

                <img class="s-preskoly__gabkocopy" src="assets/images/preskoly/Copy.webp" alt="Copy" />
                <img class="s-preskoly__gabkocopy2" src="assets/images/preskoly/Copy 2.svg" alt="Copy" />
            </article>
        </div>
        <img src="assets/images/preskoly/Wave_Line.svg" class="s-preskoly__line--gabko" alt="underline" />
        <div class=" s-preskoly-inner--benefits">
            <div class="s-preskoly-layout">
                <img class="s-preskoly-layout--hdrop1" src="assets/images/preskoly/horizontal_drop.svg"
                    alt="horizontal droplet" />
            </div>

            <article class="s-preskoly-benefits">
                <section class="s-preskoly-benefits-header">
                    <p>
                        Práve Gabkovi a deťom jemu podobným by sme chceli pomôcť
                        cez iniciatívu podporujúcu kolektívne darcovstvo krvi
                        <span class="s-preskoly__mdm"> My Drop Matters</span>
                        s podporou
                        <a class="s-preskoly__link" href="https://www.ntssr.sk" target="_blank">
                            Národnej transfúznej služby SR</a>.
                        <span class="s-preskoly__highlight">Cieľom tejto dobrovoľníckej iniciatívy je povzbudiť
                            školy, firmy, športové kluby a iné organizácie, k
                            spoločnému darovaniu krvi a k zvyšovaniu povedomia
                            o tom, ako môže darovaná krv zachraňovať životy
                            ľudí.</span>
                        Rozširovanie darcovskej základne, teda nábor prvodarcov,
                        je nesmierne dôležitý.
                    </p>
                    <p>
                        Darcovstvo je nesmierne dôležité pre hladké fungovanie
                        zdravotníctva a takéto kolektívne darcovstvo je skvelou
                        príležitosťou, ako ísť našim deťom príkladom. Naučiť
                        ich, že potreba pomáhať by sa mala stať súčasťou ich
                        života. Tým, že pomáhame našej komunite, meníme aj náš
                        svet okolo nás k lepšiemu. Práve my, organizácie
                        s veľkou komunitou ľudí, máme pri organizovaní
                        kolektívneho darcovstva krvi obrovskú skrytú silu.
                        Takzvané kolektívne darovanie krvi formou mobilného
                        odberu zásobuje väčšinu slovenských nemocníc. Preto by
                        sme radi poňali tento projekt formou
                        <span class="s-preskoly__highlight"> štafety</span>,
                        ktorú odštartovala ZŠ s MŠ Za kasárňou 2 v Bratislave.
                        Chceli by sme, aby sa postupne zapojilo čo najviac škôl.
                    </p>
                </section>
                <section class="s-preskoly-benefits-footer">
                    <img class="s-preskoly__mascot2" src="assets/images/preskoly/Drop2_illustration.svg"
                        alt="Droplet Mascot" />
                    <aside class="s-preskoly-benefits__play">
                        <!-- Play button -->
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="121"
                            height="121" viewBox="0 0 121 121" (click)="toggleVideoModal()">
                            <defs>
                                <filter id="Path_11008" x="0" y="0" width="199.014" height="199.014"
                                    filterUnits="userSpaceOnUse">
                                    <feOffset dy="13" input="SourceAlpha" />
                                    <feGaussianBlur stdDeviation="13" result="blur" />
                                    <feFlood flood-color="#961919" flood-opacity="0.102" />
                                    <feComposite operator="in" in2="blur" />
                                    <feComposite in="SourceGraphic" />
                                </filter>
                            </defs>
                            <g id="Play_Button" data-name="Play Button">
                                <g transform="matrix(1, 0, 0, 1, -39, -26)" filter="url(#Path_11008)">
                                    <path id="Path_11008-2" data-name="Path 11008"
                                        d="M60.507,0A60.507,60.507,0,1,1,17.028,18.427,60.507,60.507,0,0,1,60.507,0Z"
                                        transform="translate(39 26)" fill="#fff" />
                                </g>
                                <path id="Polygon_1" data-name="Polygon 1"
                                    d="M6.944,2.729a2,2,0,0,1,3.4,0l5.041,8.1a2,2,0,0,1-1.7,3.057H3.6a2,2,0,0,1-1.7-3.057Z"
                                    transform="translate(70.19 52.02) rotate(90)" fill="#ef2b30" />
                            </g>
                        </svg>
                        <span>Prehrať video</span>
                    </aside>
                </section>
            </article>
            <article class="s-preskoly-benefits--another">
                <h2>Ďalšie benefity darcovstva</h2>
                <section class="s-preskoly-otherbenefits">
                    <div class="s-preskoly__benefit" *ngFor="let benefit of benefits">
                        <img src="assets/images/preskoly/Drop.svg" alt="White drop" />
                        <p [innerHTML]="benefit"></p>
                    </div>
                </section>
            </article>
        </div>
        <img src="assets/images/preskoly/Wave_Line.svg" class="s-preskoly__line--benefits" alt="pink line" />
        <div class="s-preskoly-inner--contacts">
            <div class="s-preskoly-layout">
                <img class="s-preskoly-layout--hdrop2" src="assets/images/preskoly/horizontal_drop.svg"
                    alt="horizontal purple drop" />
            </div>

            <div class="s-preskoly-contacts">
                <h2>
                    Pre ďalšie informácie o iniciatíve
                    <span>Staňte sa superhrdinami pre vaše deti</span> využite
                    kontakty
                </h2>
                <section class="s-preskoly-contacts-cols">
                    <div class="s-preskoly-contact">
                        <h5>Koordinátor a autor iniciatívy <br /> My Drop Matters</h5>
                        <p>
                            Mgr. Lucia Tóthová <br /> My Drop Matters / Rare Crew
                            <a href="mailto:luciatothova@rarecrew.com">
                                luciatothova&#64;rarecrew.com</a>
                            <a href="mailto:info@mydropmatters.com">info&#64;mydropmatters.com</a>
                            <a class="no-decor" href="tel:+421948385302">+421 948 385 302</a>
                        </p>
                    </div>
                    <div class="s-preskoly-contact">
                        <h5>Koordinátor projektu za školu</h5>
                        <p>
                            Zuzana Homolová
                            <a href="mailto:info@mydropmatters.com">info&#64;mydropmatters.com</a>
                            <a class="no-decor" href="tel:+421907508867">+421 907 508 867</a>
                        </p>
                    </div>
                    <div class="s-preskoly-contact">
                        <h5>Mobilný odber krvi</h5>
                        <p>
                            <a href="mailto:mobilnyvyjazd@ntssr.sk">mobilnyvyjazd&#64;ntssr.sk</a>
                        </p>
                    </div>
                </section>
                <p class="s-preskoly__download">
                    Logo My Drop Matters, tlačová správa a iné marketingové
                    materiály sú prístupné na
                    <a href="assets/download/preskoly.zip" download="preskoly.zip">stiahnutie</a>
                    na voľné použitie každému, kto sa chce k iniciatíve
                    pripojiť, bez potreby registrovania sa.
                </p>
            </div>
        </div>

        <img src="assets/images/preskoly/Wave_Line.svg" class="s-preskoly__line--contacts" alt="pink wavy line" />

        <div class="s-preskoly-inner--print">
            <div class="s-preskoly-print">
                <h2>Tlačová grafika</h2>
                <div class="s-preskoly-print-merch">
                    <img class="s-preskoly__sticker" src="assets/images/preskoly/Sticker.webp"
                        alt="Mydropmatters sticker" />
                    <img class="s-preskoly__poster s-preskoly__shadow--poster" src="assets/images/preskoly/Plagat.webp"
                        alt="Mydropmatters poster" />
                    <img class="s-preskoly__tshirt" src="assets/images/preskoly/Tricko.webp"
                        alt="Mydropmatters t-shirt" />
                    <img class="s-preskoly__balloon" src="assets/images/preskoly/balloon.webp"
                        alt="Mydropmatters balloon" />
                </div>
            </div>
        </div>
        <img src="assets/images/preskoly/Wave_Line.svg" class="s-preskoly__line--contacts2" alt="pink wavy line" />
        <div class="s-preskoly-inner--manual">
            <div class="s-preskoly-manual">
                <h2>Manuál pre používateľov</h2>
                <div class="s-preskoly-manual-instructions">
                    <!-- left -->
                    <div class="s-preskoly-manual__instruction-list">
                        <div class="s-preskoly-manual__instruction" *ngFor="let instruction of manual | slice:0:4">
                            <img src="assets/images/preskoly/Drop.svg" alt="white drop" />
                            <p [innerHTML]="instruction"></p>
                        </div>
                    </div>

                    <!-- right -->
                    <div class="s-preskoly-manual__instruction-list">
                        <div class="s-preskoly-manual__instruction" *ngFor="let instruction of manual | slice:4:8">
                            <img src="assets/images/preskoly/Drop.svg" alt="white drop" />
                            <p [innerHTML]="instruction"></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<c-footer></c-footer>
<cookie-banner></cookie-banner>
<c-modal [isOpen]="videoModalOpen" (toggle)="toggleVideoModal()"></c-modal>