import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

interface testimony {
    description: string;
    link: string;
    website:string
}

@Component({
    selector: 'napisali',
    templateUrl: './napisali.page.html',
    styleUrls: ['./napisali.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NapisaliPageComponent implements OnInit {
    constructor(private translate: TranslateService) { }
    testimonies: testimony[] = [
        {description:'Štafetu kolektívneho darovania krvi si prebralo desať škôl', link: 'https://www.teraz.sk/slovensko/stafetu-kolektivneho-darovania-krvi-si/758407-clanok.html', website: 'https://www.teraz.sk/'},
        {description: 'Kvapka krvi pre Gabka! Prváčikovi s leukémiou pomáha celá škola, pridajte sa Darujte krv na škole!', link: 'https://zdravie.pluska.sk/novinky-a-odbornici/kvapka-krvi-pre-gabka-prvacikovi-leukemiou-pomaha-cela-skola-pridajte/2', website: 'https://zdravie.pluska.sk/'},
        {description: 'Staňte sa superhrdinami pre Vaše deti, darujte krv!', link: 'https://paralympic.sk/novinky/novinky/aktuality/stante-sa-superhrdinami-pre-vase-deti-darujte-krv/ ', website: 'https://paralympic.sk/'},
        {description: 'MY DROP MATTERS: Staňte sa superhrdinami pre vaše deti, darujte krv!', link: 'https://www.ntssr.sk/web/aktuality/detail?clanok=90', website: 'https://ntssr.sk/'},
        {description: 'Každá kvapka krvi môže zachrániť život. Čo je cieľom iniciatívy My Drop Matters?', link: 'https://www.ta3.com/relacia/912694/kazda-kvapka-krvi-moze-zachranit-zivot-co-je-cielom-iniciativy-my-drop-matters', website: 'https://www.ta3.com/ '},
        {description: 'Chceš sa aj ty stať superhrdinom pre svoje dieťa? Poď darovať krv!', link: 'https://www.expres.sk/313750/stante-sa-superhrdinami-pre-vase-deti-darujte-krv/ ', website: 'https://www.expres.sk/ '},
        {description: 'CHCEŠ SA AJ TY STAŤ SUPERHRDINOM PRE SVOJE DIEŤA? POĎ DAROVAŤ KRV!', link: 'https://www.radiomelody.sk/20177/chces-sa-aj-ty-stat-superhrdinom-pre-svoje-dieta-pod-darovat-krv/?fbclid=PAAaa0kQeUFj9KbKwhdWyncxbR8TYC6mUrinmqtCY2UtbPlGvKPQBIQcmY_5s_aem_Ac-tdyiOjtGbS5D4xY3algB5goARP1ie1K-a_60_ezORMnt2A8hBT42e2yaeEtxjF94', website: 'https://www.radiomelody.sk/'},
        {description: 'Na ZŠ Za kasárňou odštartujú štafetu kolektívneho darovania krvi', link: 'https://www.bratislavskenoviny.sk/zdravie-a-zivotny-styl/78695-na-zs-za-kasarnou-odstartuju-stafetu-kolektivneho-darovania-krvi', website: 'https://www.bratislavskenoviny.sk/' },
        {description: 'Na škole v Bratislave odštartujú štafetu kolektívneho darovania krvi', link: 'https://regiony.zoznam.sk/na-skole-v-bratislave-odstartuju-stafetu-kolektivneho-darovania-krvi/', website: 'https://regiony.zoznam.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.startitup.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://www.startitup.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://hnonline.sk/tag/my-drop-matters', website: 'https://hnonline.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom', link: 'https://webmagazin.teraz.sk/zdravie/pridajte-sa-k-my-drop-matters-pridajte/13711-clanok.html', website: 'https://webmagazin.teraz.sk/'},
        {description: 'OTS: Pridajte sa k My Drop Matters, pridajte sa k superhrdinom', link: 'https://www.tasr.sk/ots/ots-pridajte-sa-k-my-drop-matters-prid/30625-clanok.html', website: 'https://www.tasr.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.lenprezeny.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://www.lenprezeny.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://lenprechlapov.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/ ', website: 'https://lenprechlapov.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.sportdnes.sk/slovensko/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://www.sportdnes.sk/'},
        {description: 'PRIDAJTE SA K MY DROP MATTERS, PRIDAJTE SA K SUPERHRDINOM!', link: 'https://www.freebets.sk/spravodaj/36315', website: 'https://www.freebets.sk'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.hlohovecko.sk/?m=agentura_clanok&id=171361', website: 'https://www.hlohovecko.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://aktualizovane.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://aktualizovane.sk/'},
        {description: 'Pridajte Sa K My Drop Matters, Pridajte Sa K Superhrdinom!', link: 'https://topspravy.eu/blog/spravy/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://topspravy.eu/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'http://www.financnik.sk/index.php/spravodajstvo/features/item/191357-Pridajte%20sa%20k%20My%20Drop%20Matters,%20pridajte%20sa%20k%20superhrdinom!', website: 'http://www.financnik.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://spravy.izaz.eu/post-33680-pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom-.html', website:'https://spravy.izaz.eu/'},
        {description: 'Pridajte Sa K My Drop Matters, Pridajte Sa K Superhrdinom!', link: 'https://svetoviny.sk/spravy/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website:'https://svetoviny.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://infozona.sk/spravy/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://infozona.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://klocher.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://klocher.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'http://newsy.sk/2022/06/10/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'http://newsy.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.bossmedia.sk/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://www.bossmedia.sk/'},
        {description: 'Pridajte sa k My Drop Matters, pridajte sa k superhrdinom!', link: 'https://www.kosice.gratis/pridajte-sa-k-my-drop-matters-pridajte-sa-k-superhrdinom/', website: 'https://www.kosice.gratis/'},
        {description: 'Darovanie krvi na Spojená škola Novohradská', link: 'https://www.gjh.sk/o-skole/zo-zivota-skoly/240320-darovanie-krvi ', website: 'https://gjh.sk/'},
    ];
    // this is slovak only page
    ngOnInit(): void {
        this.translate.use('sk');
        localStorage.setItem('langPref', 'sk');
    }
}