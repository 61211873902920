import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
    CookieSettingsOptions,
    CookieSettingsService,
} from '../../services/cookie-settings-service.service';
import { environment } from 'apps/core/src/environments/environment';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Component({
    selector: 'cookie-banner',
    templateUrl: './cookie-banner.component.html',
    styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements OnInit, AfterViewInit {
    constructor(private settings: CookieSettingsService) { }
    cookieBannerOpen: boolean = true;
    cookieSettingsOpen: boolean = false;

    ngOnInit(): void {
        const cookie: CookieSettingsOptions & string =
            this.settings.getConsentSettings();
        if (cookie) {
            this.hideBanner();

            if (environment.production) {
                if (cookie.analytics) this.settings.injectAnalyticsScript(this.settings.liveGtagId);
            } else {
                if (cookie.analytics) this.settings.injectAnalyticsScript(this.settings.devGtagId);
            }
        }
    }
    ngAfterViewInit(): void {
        // workaround for persisted cached cookies that are held/kept on assets
        const consentedCookie = this.settings.getCookie('consent');
        if (!consentedCookie) { this.settings.removePersistedCookies(['_ga', '_ga_']); }
    }
    // this has to be done due to text being dynamically rendered from translation json
    handleClick(e: Event) {
        const target = e.target as HTMLElement;
        if (!target) return;
        if (target.className === "cookie-settings") {
            this.cookieSettingsOpen = !this.cookieSettingsOpen;
            document.body.classList.add('no-scroll');
        }
        if (target.className === "cookie-highlight") {
            window.location.href = window.location.origin + "/cookie-policy";
        }
    }

    closeSettings() {
        this.cookieSettingsOpen = false;
    }
    hideBanner() {
        this.cookieBannerOpen = false;
    }
    // cookie consent
    consentAccept() {
        this.settings.consentAll();
        this.hideBanner();
    }
    consentDecline() {
        this.settings.consent({
            ack: true,
            analytics: false,
        });
        this.hideBanner();
        this.closeSettings();
    }
}
