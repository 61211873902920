import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Language } from '../navbar/navbar.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieSettingsService } from '../../services/cookie-settings-service.service';
import { Router } from '@angular/router';

type Checkbox = HTMLInputElement & { type: 'checkbox'; checked: boolean };

@Component({
    selector: 'cookie-settings',
    templateUrl: './cookie-settings.component.html',
    styleUrls: ['./cookie-settings.component.scss'],
})
export class CookieSettingsComponent implements OnInit {
    constructor(
        private translate: TranslateService,
        private settings: CookieSettingsService,
        private router: Router
    ) { }
    @Input() isOpen: boolean = false;
    @Output() close = new EventEmitter();
    @Output() closeBanner = new EventEmitter();
    language: Language = 'en';

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((e) => {
            const lang = e.lang as Language;
            if (lang) this.language = lang;
        });
    }
    ngOnDestroy(): void {
        document.body.classList.remove('no-scroll');
    }
    closeSettings() {
        document.body.classList.remove('no-scroll');
        this.close.emit();
    }
    redirectToPolicy() {
        if (this.router.url.includes('cookie-policy')) {
            this.closeSettings();
        } else {
            this.router.navigate(['cookie-policy']);
        }
    }
    // cookie consent
    consentAll() {
        this.settings.consentAll();
        this.closeSettings();
        this.closeBanner.emit();
    }
    consentConfirm() {
        // grab onto values;
        const aConsent = document.getElementById(
            'analyticsConsent'
        ) as Checkbox;


        this.settings.consent({
            ack: true,
            analytics: aConsent.checked ?? false,
        });
        this.closeSettings();
        this.closeBanner.emit();
    }
}
