import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home/home.page';
import { TosPageComponent } from './pages/tos/tos.page';
import { NotFoundPageComponent } from './pages/404/not-found.page';
import { EventsPageComponent } from './pages/events/events.page';
import { PreskolyPageComponent } from './pages/preskoly/preskoly.page';
import { ConfirmationPageComponent } from './pages/confirmation/confirmation.page';
import { StafetaPageComponent } from './pages/stafeta/stafeta.page';
import { CookiePolicyPageComponent } from './pages/cookie-policy/cookie-policy.page';
import { NapisaliPageComponent } from './pages/napisali/napisali.page';

const routes: Routes = [
    {
        path: 'en',
        component: HomePageComponent,
    },
    {
        path: 'sk',
        component: HomePageComponent,
    },
    {
        path: '',
        component: HomePageComponent,
        data: {
            title: "Home"
        }
    },
    {
        path: 'preskoly',
        component: PreskolyPageComponent,
        data: {
            title: "Preskoly"
        }
    },
    {
        path: 'stafeta',
        component: StafetaPageComponent,
        data: {
            title: "Stafeta"
        }
    },

    {
        path: 'tos',
        component: TosPageComponent,

        data: {
            title: "Terms of use"
        }
    },
    {
        path: 'cookie-policy',
        component: CookiePolicyPageComponent,
        data: {
            title: "Cookie Policy"
        }
    },
    {
        path: 'events',
        component: EventsPageComponent,
        data: {
            title: "Events"
        }
    },
    {
        path: 'confirmation',
        component: ConfirmationPageComponent,
        data: {
            title: "Download"
        }
    },
    {
        path: 'napisali',
        component: NapisaliPageComponent,
        data: {
            title: "Napisali"
        }
    },
    {
        path: '**',
        component: NotFoundPageComponent, //404
        data: {
            title: "Not Found"
        }
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'top',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
