import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RouteHandlerService } from '../../services/route-handler-service';

@Component({
    selector: 'preskoly',
    templateUrl: './preskoly.page.html',
    styleUrls: ['./preskoly.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PreskolyPageComponent implements OnInit {
    constructor(private translate: TranslateService) { }
    benefits: string[] = [
        'Kolektívne darovanie krvi na vašej škole spojí rodičov a priateľov školy. Pre deti sa stávate, vy rodičia, ich superhrdinami.',
        'Vyšlete signál smerom von, že altruistický prístup je súčasťou vašej školskej kultúry.',
        'Ako štandardnú súčasť každého transfúzneho odberu každý darca zároveň absolvuje lekársku prehliadku.',
        'Logo školy bude vyobrazené na stránke <a href="/">www.mydropmatters.sk.</a>',
        'Zapojíte sa do celospoločensky prospešnej aktivity a podporíte nielen Gabka, ale aj iné choré detičky.',
        'Môžete sa pýšiť titulom školy so spoločenskou zodpovednosťou.',
        'Získate dobrý pocit, ktorý sa nedá kúpiť.',
    ];
    manual: string[] = [
        // left
        'Kontaktujte <a href="https://www.ntssr.sk" target="_blank">NTS SR</a>, pre stanovenie termínu mobilného odberu krvi na emailovej adrese <a href="mailto:mobilnyvyjazd@ntssr.sk">mobilnyvyjazd@ntssr.sk</a > Všetky informácie k mobilným odberom nájdete <a href="https://www.ntssr.sk" target="_blank">tu</a>. Všetky informácie k samotnému priebehu odberu vám dá kontaktná osoba z Národnej transfúznej služby SR.',
        'Oslovte interne rodičov ako by vedeli podporiť akciu napríklad tlač plagátov, výroba nálepiek pre deti, výroba vlastných tričiek s logom školy. Tieto aktivity sú potrebné na samotnú propagáciu akcie, aby sa vám prihlásilo aspoň 30 a viac darcov.',
        'Stiahnite si grafiky ku kampani <a href="assets/download/preskoly.zip" download="preskoly.zip">My Drop Matters</a>.\n Je na vás, ktoré sa rozhodnete použiť. Všetky grafiky sú voľne prístupne bezodplatne a bez nutnosti registrácie.',
        'Pošlite tlačovú správu pre rodičov s potrebnými informáciami, kde bude komunikovaná samotná myšlienka, dátum akcie a prihlasovací formulár pre rodičov. Nechajte sa inšpirovať našou tlačovou správou.',
        // right
        'Vyveste tlačoviny týkajúce sa akcie do vestibulu školy.',
        'Motivujte aj inú spriaznenú školu aby sa do akcie zapojila nech sa šíri ďalej myšlienka potreby darovania krvi. Každý darca predsa vie zachrániť až 3 ľudské životy.',
        'Pošlite svoje logo školy alebo organizácie na <a href="mailto:info@mydropmatters.com">info@mydropmatters.com</a> a budete následne vyobrazený na stránke <a href="/">www.mydropmatters.sk</a> v sekcii “Zapojili sa”.​',
        'Dajte na vašej stránke vedieť, že podporujete iniciatívu My Drop Matters a umiestnite naše logo odkazujúce na <a href="/">www.mydropmatters.sk</a>.',
    ];
    videoModalOpen: boolean = false;
    toggleVideoModal() {
        this.videoModalOpen = !this.videoModalOpen;
    }
    // this is slovak only page
    ngOnInit(): void {
        this.translate.use('sk');
        localStorage.setItem('langPref', 'sk');
    }
}
