<c-navbar></c-navbar>

<section class="s-events">
    <h1>{{'Events.Title' | translate}}</h1>
    <div class="s-events-mosaic">
        <!-- you have to adjust eventImages in events.page.ts in order for them to render -->
        <ng-container *ngFor="let image of eventImages;index as i">
            <div class="s-events__item">
                <img (click)="selectImage(i+1)" src="assets/images/new-events/{{i+1}}.webp"
                    alt="Photo from blood donation event" loading="lazy" />
                <div class="s-events__item-overlay">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="lucide lucide-zoom-in">
                        <circle cx="11" cy="11" r="8" />
                        <line x1="21" x2="16.65" y1="21" y2="16.65" />
                        <line x1="11" x2="11" y1="8" y2="14" />
                        <line x1="8" x2="14" y1="11" y2="11" />
                    </svg>
                </div>
            </div>

        </ng-container>
    </div>
</section>

<!-- fullscreen overlay -->
<div class="s-events-viewer" *ngIf="currentImage">
    <div class="s-events-viewer-inner">
        <div class="s-events-viewer-photo">
            <img src="assets/images/new-events/{{currentImage}}.webp" alt="Photo from blood donation event" />
        </div>

        <button class="s-events-viewer__close" tabindex="0" (click)="closeViewer()">
            &times;
        </button>
        <button class="s-events-viewer__prev" tabindex="0" (click)="showPrev()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
            </svg>
        </button>
        <button class="s-events-viewer__next" tabindex="0" (click)="showNext()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
            </svg>
        </button>
    </div>
</div>
<c-footer></c-footer>
<cookie-banner></cookie-banner>