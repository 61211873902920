import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../components/navbar/navbar.component';
import { Router } from '@angular/router';

@Component({
    selector: 'tos',
    templateUrl: './tos.page.html',
    styleUrls: ['./tos.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TosPageComponent implements OnInit {
    constructor(private translate: TranslateService, private router: Router) { }
    language: Language = 'en';

    ngOnInit(): void {
        const langPref = localStorage.getItem("langPref") as Language;
        if (langPref) this.language = langPref;
        this.translate.onLangChange.subscribe((e) => {
            const lang = e.lang;
            if (lang) this.language = e.lang as Language;
        });

    }
}
