<c-navbar></c-navbar>

<section class="s-cookiepolicy">
    <h1 class="s-cookiepolicy__heading">
        {{'Cookie_Policy.Heading' | translate}}
    </h1>
    <p class="s-cookiepolicy__subheading" [innerHTML]="'Cookie_Policy.Info' | translate"></p>
    <ul>
        <li>Chrome: <a class="s-cookiepolicy__link"
                href="{{'Tos.Links.Chrome.Link' | translate}}">{{'Tos.Links.Chrome.Title' | translate}}</a>
        </li>
        <li>Firefox: <a class="s-cookiepolicy__link"
                href="{{'Tos.Links.Firefox.Link' | translate}}">{{'Tos.Links.Firefox.Title' | translate}}</a>
        </li>
        <li>Safari: <a class="s-cookiepolicy__link"
                href="{{'Tos.Links.Safari.Link' | translate}}">{{'Tos.Links.Safari.Title' | translate}}</a>
        </li>
        <li>Edge: <a class="s-cookiepolicy__link" href="{{'Tos.Links.Edge.Link' | translate}}">{{'Tos.Links.Edge.Title'
                |
                translate}}</a></li>
        <li>Internet Explorer: <a class="s-cookiepolicy__link"
                href="{{'Tos.Links.Explorer.Link' | translate}}">{{'Tos.Links.Explorer.Title' | translate}}</a>
        </li>
    </ul>

    <p class="s-cookiepolicy__subheading" [innerHTML]="'Cookie_Policy.Info2' | translate"></p>

    <h4 class="s-cookiepolicy__category">
        {{'Cookie_Policy_Table.Essential.Title' | translate}}
    </h4>
    <table class="s-cookiepolicy-table">
        <thead>
            <td>{{'Cookie_Policy_Table.Column1' | translate}}</td>
            <td>{{'Cookie_Policy_Table.Column2' | translate}}</td>
            <td>{{'Cookie_Policy_Table.Column3' | translate}}</td>
        </thead>
        <tr>
            <td>consent</td>
            <td>
                {{'Cookie_Policy_Table.Essential.Consent.Expiration_Time' |
                translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Essential.Consent.Description' | translate}}
            </td>
        </tr>
        <tr>
            <td>ARRAffinitySameSite</td>
            <td>
                {{'Cookie_Policy_Table.Essential.ARRAffinitySameSite.Expiration_Time' |
                translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Essential.ARRAffinitySameSite.Description' | translate}}
            </td>
        </tr>
        <tr>
            <td>ARRAffinity</td>
            <td>
                {{'Cookie_Policy_Table.Essential.ARRAffinity.Expiration_Time' |
                translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Essential.ARRAffinity.Description' | translate}}
            </td>
        </tr>
        <!-- Translate -->
        <tr>
            <td scope="row" class="word-break">PREF</td>
            <td class="no-wrap">{{'Cookie_Policy_Table.Essential.PREF.Expiration_Time' | translate}}</td>
            <td>
                {{'Cookie_Policy_Table.Essential.PREF.Description' | translate}}
            </td>
        </tr>

        <tr>
            <td scope="row" class="word-break">VISITOR_PRIVACY_METADATA</td>
            <td class="no-wrap">{{'Cookie_Policy_Table.Essential.VISITOR_PRIVACY_METADATA.Expiration_Time' | translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Essential.VISITOR_PRIVACY_METADATA.Description' | translate}}
            </td>
        </tr>

        <tr>
            <td scope="row" class="word-break">VISITOR_INFO1_LIVE</td>
            <td class="no-wrap">{{'Cookie_Policy_Table.Essential.VISITOR_INFO1_LIVE.Expiration_Time' | translate}}</td>
            <td>
                {{'Cookie_Policy_Table.Essential.VISITOR_INFO1_LIVE.Description' | translate}}
            </td>
        </tr>


        <tr>
            <td scope="row" class="word-break">YSC</td>
            <td class="no-wrap">{{'Cookie_Policy_Table.Essential.YSC.Expiration_Time' | translate}}</td>
            <td>
                {{'Cookie_Policy_Table.Essential.YSC.Description' | translate}}
            </td>
        </tr>
        <!-- end: translate above -->
    </table>

    <h4 class="s-cookiepolicy__category">
        {{'Cookie_Policy_Table.Analytics.Title' | translate}}
    </h4>
    <table class="s-cookiepolicy-table">
        <thead>
            <td>{{'Cookie_Policy_Table.Column1' | translate}}</td>
            <td>{{'Cookie_Policy_Table.Column2' | translate}}</td>
            <td>{{'Cookie_Policy_Table.Column3' | translate}}</td>
        </thead>
        <tr>
            <td>ga</td>
            <td>
                {{'Cookie_Policy_Table.Analytics.ga.Expiration_Time' |
                translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Analytics.ga.Description' | translate}}
            </td>
        </tr>
        <tr>
            <td>_ga</td>
            <td>
                {{'Cookie_Policy_Table.Analytics._ga.Expiration_Time' |
                translate}}
            </td>
            <td>
                {{'Cookie_Policy_Table.Analytics._ga.Description' | translate}}
            </td>
        </tr>
    </table>
    <p class="s-cookiepolicy__furtherdetails" [innerHTML]="'Cookie_Policy.Further_Details' | translate"></p>
    <p class="s-cookiepolicy__contact" [innerHTML]="'Cookie_Policy.Contact' | translate"></p>
</section>

<c-footer></c-footer>
<cookie-banner></cookie-banner>

<aside class="s-cookiepolicy-toast" *ngIf="toastOpen">

    <div class="s-cookiepolicy-toast-content">
        <span *ngIf="!toastAlreadyOpen">{{'Cookie_Toast_Text' | translate}}</span>
        <span *ngIf="toastAlreadyOpen">{{'Cookie_Toast_Text_Already' | translate}}</span>
        <span class="s-cookiepolicy-toast__close" (click)="closeToast()">&times;</span>
        <svg class="s-cookiepolicy-toast__poly" width="56" height="62" viewBox="0 0 56 62" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M55.6017 61.259L0.327624 37.2834L35.0028 0.875914L55.6017 61.259Z" fill="#16A34A" />
        </svg>
    </div>

    <img class="s-cookiepolicy-toast__mascot" loading="eager" src="assets/images/preskoly/Drop_illustration.svg" />
</aside>