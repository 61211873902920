<footer class="c-footer" [ngClass]="activeSegment === 'preskoly' ? 'c-footer--preskoly' : ''">
    <div class="c-footer-background--pink" [ngClass]="language === 'sk' ? 'sk' : 'en'"></div>
    <div class="c-footer-background--red" [ngClass]="language === 'sk' ? 'sk' : 'en'"></div>

    <div class="c-footer-content">
        <!-- pink section -->
        <section class="c-footer-content--pink" [ngClass]="language === 'sk' ? 'sk' : 'en'">
            <div class="c-footer-content-inner">
                <div class="c-footer-logos" *ngIf="activeSegment !== 'preskoly'">
                    <a href="/">
                        <img loading="lazy" class="c-footer__mdm"
                            src="assets/images/new-navbar/my drop matters_logo.svg" alt="Mydropmatters logo" />
                    </a>
                    <div class="c-footer__separator--pink"></div>
                    <div class="c-footer-sponsor--rc">
                        <span>{{ 'Footer.PoweredBy' | translate }}</span>
                        <a href="https://rarecrew.com/" target="_blank">
                            <img loading="lazy" class="c-footer__logo--rc"
                                src="assets/images/new-home/Rarecrew_logo.svg" alt="Rarecrew logo" />
                        </a>
                    </div>
                    <div class="c-footer-sponsor">
                        <span>{{ 'Footer.SupportedBy' | translate }}</span>
                        <a [href]="
                                language === 'sk'
                                    ? 'https://www.ntssr.sk/'
                                    : 'https://www.ntssr.sk/en'
                            " target="_blank">
                            <img loading="lazy" class="c-footer__logo--nts" src="assets/images/new-home/nts_logo.svg"
                                alt="NTS logo" />
                        </a>
                    </div>
                </div>
                <div class="c-footer-contact" *ngIf="activeSegment !== 'preskoly'">
                    <img loading="lazy" class="c-footer__reddrop" src="assets/images/new-home/Information_icon.svg"
                        alt="Red drop with I inside" />
                    <span class="c-footer__contact-info">
                        {{ 'Footer.Description' | translate }}
                        <a href="mailto:info@mydropmatters.com"
                            class="c-footer__highlight">info&#64;mydropmatters.com</a>
                    </span>
                </div>

                <!-- preskoly content -->
                <div class="c-footer--preskoly-content" *ngIf="activeSegment === 'preskoly'">
                    <span class="c-footer--preskoly__support">S podporou</span>
                    <div class="c-footer--preskoly-logos">
                        <a [href]="language === 'sk'
                        ? 'https://www.ntssr.sk/'
                        : 'https://www.ntssr.sk/en'" referrerpolicy="no-referrer">
                            <img loading="lazy" class="c-footer--preskoly__logo--nts"
                                src="assets/images/preskoly/NTS.webp" alt="NTS logo" />
                        </a>
                        <a href="https://rarecrew.com/" referrerpolicy="no-referrer" target="_blank">
                            <img loading="lazy" class="c-footer--preskoly__logo--rc"
                                src="assets/images/preskoly/Rarecrew.webp" alt="Rarecrew logo" />
                        </a>

                        <a href="https://paralympic.sk/" target="_blank" referrerpolicy="no-referrer">
                            <img loading="lazy" class="c-footer--preskoly__logo--spv"
                                src="assets/images/preskoly/SPV.webp" alt="SPV logo" />
                        </a>
                        <a href="https://sztps.sk/" target="_blank" referrerpolicy="no-referrer">
                            <img loading="lazy" class="c-footer--preskoly__logo--sztps"
                                src="assets/images/preskoly/SZP.webp" alt="SZTPS logo" />
                        </a>

                        <a href="https://www.detomsrakovinou.sk/" target="_blank" referrerpolicy="no-referrer">
                            <img loading="lazy" class="c-footer--preskoly__logo--sztps"
                                src="assets/images/preskoly/Detomsrakovinou.webp" alt="Detom rakovinou logo" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <!-- red section -->
        <section class="c-footer-content--red">
            <div class="c-footer-content-inner--red">
                <div class="c-footer-links">
                    <div class="c-footer-links--other">
                        <a class="c-footer__link" routerLinkActive="c-footer__link--active" routerLink="/preskoly"
                            target="_blank" *ngIf="language === 'sk'">Pre školy</a>

                        <div *ngIf="language === 'sk'" class="c-footer__separator--other"></div>

                        <a class="c-footer__link" routerLinkActive="c-footer__link--active" routerLink="/events"
                            target="_blank">{{
                            'Footer.Events' | translate }}</a>

                        <div *ngIf="language === 'sk'" class="c-footer__separator--other"></div>

                        <a class="c-footer__link" routerLinkActive="c-footer__link--active" routerLink="/napisali"
                            target="_blank" *ngIf="language === 'sk'">Napísali o nás</a>
                    </div>

                    <div class="c-footer-copyright">
                        <span>© My Drop Matters 2023</span>
                        <div class="c-footer__separator"></div>
                        <a class="c-footer__link" routerLink="/tos" routerLinkActive="c-footer__link--active">{{
                            'Footer.Tos' | translate }}</a>
                        <a class="c-footer__link" routerLink="/cookie-policy"
                            routerLinkActive="c-footer__link--active">{{ 'Footer.Cookie_Policy' | translate }}</a>
                    </div>
                </div>

                <!-- bottom content -->
                <div class="c-footer-socials">
                    <a href="https://www.instagram.com/mydropmatters_official/" target="_blank">
                        <img loading="lazy" width="48" height="48" src="assets/images/new-footer/Instagram_icon.svg"
                            alt="Instagram icon" />
                    </a>
                    <a href="https://www.facebook.com/mydropmatters/" target="_blank">
                        <img loading="lazy" width="48" height="48" src="assets/images/new-footer/Facebook_icon.svg"
                            alt="Facebook icon" />
                    </a>
                    <a [href]="getVideoUrl()" target="_blank">
                        <img loading="lazy" width="48" height="48" src="assets/images/new-footer/Youtube_icon.svg"
                            alt="Youtube icon" />
                    </a>
                </div>
            </div>
        </section>
    </div>
</footer>