import {
    Component,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { SupportedLanguage } from '../../app.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: 'c-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
    constructor(private translate: TranslateService, private sanitizer: DomSanitizer,) { }
    @Input() isOpen: boolean;
    @Output() toggle = new EventEmitter();
    language: SupportedLanguage = "en";
    videoURL: SafeResourceUrl;

    toggleModal() {
        // emits custom callback
        this.toggle.emit();
    }
    ngOnInit(): void {
        this.translate.onLangChange.subscribe(l => {
            const lang = l.lang;
            if (lang) {
                this.language = lang as SupportedLanguage;
                this.getVideoUrl();
            }
        });
    }
    getVideoUrl() {
        const videoId = this.language === 'sk' ? '1HH1oviPfTU' : 'ZjXLnVk3njg';
        const url = `https://www.youtube.com/embed/${videoId}?autoplay=1`
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
