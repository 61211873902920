import { Component, ViewEncapsulation } from '@angular/core';
import { CookieSettingsService } from '../../services/cookie-settings-service.service';

@Component({
    selector: 'cookie-policy',
    templateUrl: './cookie-policy.page.html',
    styleUrls: ['./cookie-policy.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CookiePolicyPageComponent {
    constructor(private cookieSettings: CookieSettingsService) { }
    toastOpen: boolean = false;
    toastAlreadyOpen: boolean = false;
    toastTimeout;

    handleClick(e: Event) {
        const target = e.target as HTMLSpanElement;
        if (target && target.classList.contains('delete-cookies')) {
            this.deleteAllCookies();
            // open toast,close after 5 seconds
            if (this.toastOpen) this.toastAlreadyOpen = true;
            this.toastOpen = true;
            clearTimeout(this.toastTimeout);
            this.toastTimeout = setTimeout(() => {
                this.toastOpen = false;
                this.toastAlreadyOpen = false;
            }, 5000);
            this.cookieSettings.removeScripts();
        }
    }
    deleteAllCookies() {
        var cookies = document.cookie.split("; ");

        for (var c = 0; c < cookies.length; c++) {
            var d = ("." + window.location.hostname).split(".");

            while (d.length > 0) {
                var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + '; path=';

                var p = location.pathname.split('/');
                document.cookie = cookieBase + '/';
                while (p.length > 0) {
                    document.cookie = cookieBase + p.join('/');
                    p.pop();
                };
                d.shift();
            }
        }
    }


    closeToast() {
        this.toastOpen = false;
        clearTimeout(this.toastTimeout);
    }
}
