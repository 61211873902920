<div class="settings-backdrop" *ngIf="isOpen">
    <div class="c-settings">
        <div class="c-settings-heading">
            <h1>{{ 'Cookie_Settings.Title' | translate }}</h1>
            <div class="c-settings__close" (click)="closeSettings()">
                &times;
            </div>
        </div>
        <div class="c-settings__separator"></div>
        <p class="c-settings__policy" [innerHTML]="'Cookie_Settings.Warning' | translate"></p>
        <div class="c-settings-actions">
            <a class="highlight c-settings__link" (click)="redirectToPolicy()">{{ 'Cookie_Settings.Policy' | translate
                }}</a>
            <button type="button" class="c-settings__button" (click)="consentAll()">
                {{'Cookie_Settings.Button.Allow' | translate}}
            </button>
        </div>

        <div class="c-settings-preferences">
            <h6>{{ 'Cookie_Settings.Preferences' | translate }}</h6>
            <!-- bootstrap accordion -->
            <div id="accordion" class="c-accordion">
                <!-- Necessary Cookies -->
                <div class="c-accordion-container">
                    <div class="c-accordion__header">
                        <button class="btn c-accordion__btn" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                            aria-expanded="false" aria-controls="collapseOne">
                            <svg class="c-accordion__icon--one" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <path d="M5 12h14" />
                                <path d="M12 5v14" />
                            </svg>
                            <svg class="c-accordion__icon--one" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <path d="M5 12h14" />
                            </svg>

                            <span>{{
                                'Cookie_Settings.Necessary.Title' | translate
                                }}</span>
                        </button>
                        <!-- Toggle -->
                        <div class="c-accordion__toggle c-accordion__toggle--disabled">
                            <input type="checkbox" checked disabled />
                        </div>
                    </div>
                    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordion">
                        <span class=" c-accordion__body">{{'Cookie_Settings.Necessary.Text' | translate}}</span>
                    </div>
                </div>
                <!--  -->
                <!-- Analytics Cookies -->
                <div class="c-accordion-container">
                    <div class="c-accordion__header">
                        <button class="btn c-accordion__btn" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                            aria-expanded="false" aria-controls="collapseTwo">
                            <svg class="c-accordion__icon--two" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <path d="M5 12h14" />
                                <path d="M12 5v14" />
                            </svg>
                            <svg class="c-accordion__icon--two" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round">
                                <path d="M5 12h14" />
                            </svg>

                            <span>{{
                                'Cookie_Settings.Analytics.Title' | translate
                                }}</span>
                        </button>
                        <!-- Toggle -->
                        <div class="c-accordion__toggle">
                            <input type="checkbox" id="analyticsConsent" />
                        </div>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                        <span class=" c-accordion__body">{{'Cookie_Settings.Analytics.Text' | translate}}</span>

                    </div>
                </div>
                <!--  -->
                <button type="button" class="c-settings__button" (click)="consentConfirm()">
                    {{ 'Cookie_Settings.Button.Confirm' | translate }}
                </button>
            </div>
        </div>
    </div>