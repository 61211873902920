import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home/home.page';
import { TosPageComponent } from './pages/tos/tos.page';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotFoundPageComponent } from './pages/404/not-found.page';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { EventsPageComponent } from './pages/events/events.page';
import { StafetaPageComponent } from './pages/stafeta/stafeta.page';
import { ConfirmationPageComponent } from './pages/confirmation/confirmation.page';
import { CookieSettingsComponent } from './components/cookie-settings/cookie-settings.component';
import { CookiePolicyPageComponent } from './pages/cookie-policy/cookie-policy.page';
import { PreskolyPageComponent } from './pages/preskoly/preskoly.page';
import { NapisaliPageComponent } from './pages/napisali/napisali.page';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const ANGULAR = [
    CommonModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
];
const MODULES = [AppRoutingModule, SlickCarouselModule];
const PAGES = [
    HomePageComponent,
    TosPageComponent,
    NotFoundPageComponent,
    EventsPageComponent,
    StafetaPageComponent,
    PreskolyPageComponent,
    ConfirmationPageComponent,
    CookiePolicyPageComponent,
    NapisaliPageComponent,
];
const COMPONENT = [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    ModalComponent,
    CookieBannerComponent,
    CookieSettingsComponent,
];
@NgModule({
    declarations: [...PAGES, ...COMPONENT],
    imports: [
        BrowserModule,
        TranslateModule,
        ...ANGULAR,
        ...MODULES,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            isolate: true,
        }),
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
