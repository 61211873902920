<header class="c-navbar">
    <div class="c-navbar-inner" [ngClass]="[currentLanguage === 'en' ? 'en' : 'sk', mobileMenuOpen ? 'menu-open': '']">
        <!-- logo -->
        <img (click)="goHome()" class="c-navbar-inner__logo" src="assets/images/new-navbar/my drop matters_logo.svg"
            alt="Mydropmatters" />
        <nav class="c-navbar-inner-actions">
            <div class="c-navbar--desktop" *ngIf="currentLanguage === 'sk'">
                <div class="c-navbar__level-1">
                    <ul class="c-navbar__list">
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/preskoly" class="c-navbar__url-level-1">Pre
                                školy</a>
                        </li>
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/events" class="c-navbar__url-level-1">Zrealizované
                                akcie</a>
                        </li>
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/napisali" class="c-navbar__url-level-1">Napísali o
                                nás</a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- take part button -->
            <a href="#download" (click)="scrollToDownload($event)" class="blood_button">
                <span>{{ 'Navbar.Button_text' | translate }}</span>
                <!-- last div is here to make space-around work nicely -->
                <div></div>
            </a>

            <div class="c-navbar-inner-actions__separator"></div>
            <div class="c-navbar-inner-actions__lang" (click)="toggleLanguage()"
                [ngClass]="[currentLanguage === 'en' ? 'en' : 'sk']">
                <!-- slovak -->
                <div *ngIf="currentLanguage === 'en'">
                    <img src="assets/images/new-navbar/SK_icon.svg" alt="Language flag" />
                    <span>sk</span>
                </div>
                <!-- english -->
                <div *ngIf="currentLanguage === 'sk'">
                    <img src="assets/images/new-navbar/ENG_icon.svg" alt="Language flag" />
                    <span>en</span>
                </div>
            </div>
            <!-- language switcher -->

            <button class="btn c-navbar--toggler" type="button" aria-label="Toggle navigation"
                (click)="toggleMobileMenu()">
                <div *ngIf="!mobileMenuOpen"><img class="c-navbar--toggler-burger"
                        src="assets/images/navbar/Hamubrger_menu.svg" alt="hamburger icon"></div>
                <div *ngIf="mobileMenuOpen"><img class="c-navbar--toggler-cross"
                        src="assets/images/navbar/Cross_menu.svg" alt="cross icon"></div>
            </button>

            <!-- Mobile menu -->
            <div class="c-navbar--mobile" *ngIf="mobileMenuOpen">
                <div class="c-navbar__level-1">
                    <ul class="c-navbar__list">
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/preskoly" class="c-navbar__url-level-1">Pre
                                školy</a>
                        </li>
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/events" class="c-navbar__url-level-1">Zrealizované
                                akcie</a>
                        </li>
                        <li class="c-navbar__item-level-1">
                            <a routerLinkActive="active" routerLink="/napisali" class="c-navbar__url-level-1">Napísali o
                                nás</a>
                        </li>
                    </ul>
                </div>
                <div class="c-navbar__divider"></div>
                <div class="c-navbar__lang" (click)="toggleLanguage()">
                    <!-- slovak -->
                    <div *ngIf="currentLanguage === 'en'">
                        <img src="assets/images/new-navbar/SK_icon.svg" alt="Language flag" />
                        <span>sk</span>
                    </div>
                    <!-- english -->
                    <div *ngIf="currentLanguage === 'sk'">
                        <img src="assets/images/new-navbar/ENG_icon.svg" alt="Language flag" />
                        <span>en</span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</header>