import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class RouteHandlerService {
    constructor(private router: Router, private translate: TranslateService) {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                // initial page load
                this.init(e.url);
            }
            if (e instanceof NavigationEnd) {
                // after redirection ends
                this.init(e.urlAfterRedirects);
            }
        });
    }
    activeSegment: BehaviorSubject<string> = new BehaviorSubject('');
    activeURL: BehaviorSubject<string> = new BehaviorSubject(window.location.href);


    // sk only segments
    slovakOnlySegments: string[] = ['preskoly', 'napisali'];
    init(route: string) {
        this.activeURL.next(window.location.href);
        const segment = this.getFirstSegment(route);
        if (segment) this.activeSegment.next(segment);
        else this.activeSegment.next('');
        const lang = this.getLangByTLD();
        // .com tld
        if (lang === 'en' && this.isSlovakOnly(segment)) {
            this.router.navigate(['/']).then(v => {
                this.activeSegment.next('');
                this.translate.use('en');
                localStorage.setItem('langPref', 'en')
            });
        }
    }
    isSlovakOnly(segment: string) {
        return this.slovakOnlySegments.includes(segment);
    }

    getFirstSegment(url: string) {
        return url.split('/').reverse()[0];
    }
    getLangByTLD() {
        return this.activeURL.value.split('.').reverse()[0].split('/')[0] === 'sk' ? 'sk' : 'en';
    }
}
