import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'stafeta',
    templateUrl: './stafeta.page.html',
    styleUrls: ['./stafeta.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StafetaPageComponent implements AfterViewInit, OnInit {
    constructor(private router: Router) { }
    @ViewChild('downloadLink') downloadLink: ElementRef;
    ngAfterViewInit(): void {
        this.downloadLink.nativeElement.click();
    }
    ngOnInit(): void {
        document.body.style.overflow = 'hidden';
    }
    goHome() {
        this.router.navigate(['/']);
        document.body.style.overflow = 'auto';
    }
}