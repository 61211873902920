<div class="c-cookiebanner" *ngIf="cookieBannerOpen">
    <div class="c-cookiebanner-inner">
        <span class="c-cookiebanner__text" (click)="handleClick($event)" [innerHTML]="'Cookie_Banner.Text' | translate">
        </span>
        <div class="c-cookiebanner-actions">
            <button (click)="consentAccept()">
                {{ 'Cookie_Banner.Actions.Accept' | translate }}
            </button>
        </div>
    </div>
</div>

<cookie-settings [isOpen]="cookieSettingsOpen" (close)="closeSettings()" (closeBanner)="hideBanner()" />