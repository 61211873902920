<c-navbar></c-navbar>
<section class="s-napisali">
    <div class="s-napisali-content">
        <div class="s-napisali-inner">
            <h1 class="s-napisali__heading">
                Napísali o nás
            </h1>

            <div class="s-napisali__list">
                <ng-container *ngFor="let t of testimonies">
                    <div class="s-napisali__item">
                        <div class="s-napisali__wave"></div>
                        <a class="s-napisali__link" href="{{t.link}}" target="_blank">{{t.description}}</a>
                        <a class="s-napisali__web" href="{{t.website}}" target="_blank">{{t.website}}</a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>
<c-footer></c-footer>
<cookie-banner></cookie-banner>