import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../components/navbar/navbar.component';
@Component({
    selector: 'events',
    templateUrl: './events.page.html',
    styleUrls: ['./events.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EventsPageComponent implements OnInit {
    constructor(private translate: TranslateService) { }
    eventImages: number[] = Array(25);
    language: Language;
    currentImage: number | null = null;


    ngOnInit(): void {
        this.translate.onLangChange.subscribe((e) => {
            this.language = e.lang as Language;
        });
        window.addEventListener("keydown", (e) => {

            switch (e.key) {
                case "Enter":
                    const target = document.activeElement.classList.value.split("__")[1];

                    switch (target) {
                        case "close":
                            this.closeViewer();
                            break;
                        case "prev":
                            this.showPrev();
                            break;
                        case "next":
                            this.showNext();
                            break;
                        default:
                            break;
                    }
                    break;

                case "ArrowLeft": this.showPrev();
                    break;

                case "ArrowRight": this.showNext();
                    break;

                case "Escape": this.closeViewer();
                    break;
                default: break;
            }

        });
    }

    selectImage(image: number) {
        if (!image) return;

        this.currentImage = image;
        document.body.classList.add("no-scroll");
    }
    closeViewer() {
        this.currentImage = null;
        document.body.classList.remove("no-scroll");
    }

    showPrev() {
        if (this.currentImage > 1) this.currentImage--;
        else this.currentImage = this.eventImages.length - 1;
    }
    showNext() {
        if (this.currentImage <= this.eventImages.length - 1) this.currentImage++;
        else this.currentImage = 1;

    }
}
