import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

type Language = 'en' | 'sk';

@Component({
    selector: 'confirmation',
    templateUrl: './confirmation.page.html',
    styleUrls: ['./confirmation.page.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationPageComponent implements OnInit, AfterViewInit {
    constructor(private router: Router, private translate: TranslateService) { }
    @ViewChild('downloadLink') downloadLink: ElementRef;

    language: Language = 'en';
    ngOnInit(): void {
        document.body.style.overflow = 'hidden';
        this.translate.onLangChange.subscribe((e) => {
            const lang = e.lang;
            if (lang) this.language = lang as Language;
        });
    }
    ngAfterViewInit(): void {
        this.downloadLink.nativeElement.click();
    }

    goHome() {
        // reset styling
        document.body.style.overflow = 'auto';
    }
}
