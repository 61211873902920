import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';


export type CookieSettingsOptions = {
    ack: boolean;
    analytics: boolean;
    targeting: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class CookieSettingsService {
    constructor() { }
    cookieKey: string = 'consent';
    // gtag
    isGtagInjected: boolean = false;
    liveGtagId: string = 'G-21BPBCMQCK';
    devGtagId: string = 'G-8VG56DQZC1';
    gtagScriptId: string = 'gtag-script';

    consent(options: Partial<CookieSettingsOptions>) {
        document.cookie = `${this.cookieKey}=${JSON.stringify(
            options
        )};domain=.${this.getRootDomain()}`;
        if (options.analytics) {
            if (environment.production) this.injectAnalyticsScript(this.liveGtagId);
            else this.injectAnalyticsScript(this.devGtagId);
        }
        if (options.targeting) {
            if (environment.production) this.injectTargetingScript(this.liveGtagId);
            else this.injectTargetingScript(this.devGtagId);
        }
    }
    // agreed to everything
    consentAll() {
        document.cookie = `${this.cookieKey}=${JSON.stringify({
            ack: true,
            analytics: true,
            targeting: true,
        })};domain=.${this.getRootDomain()}`;

        if (environment.production) {
            this.injectAnalyticsScript(this.liveGtagId);
            this.injectTargetingScript(this.liveGtagId);
        } else {
            this.injectAnalyticsScript(this.devGtagId);
            this.injectTargetingScript(this.devGtagId);
        }

    }

    getConsentSettings() {
        const cookies = document.cookie.split(';');

        for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie.trim().split('=');
            if (cookieKey === this.cookieKey) {
                return JSON.parse(decodeURIComponent(cookieValue));
            }
        }

        return null;
    }
    getRootDomain(): string {
        if (location.hostname === 'localhost') {
            return 'localhost';
        } else {
            const domainParts = location.hostname.split('.');

            if (domainParts.length >= 2) {
                return domainParts.slice(-2).join('.');
            }
            return domainParts.join('.')
        }
    }
    // script injection
    injectAnalyticsScript(measurementId: string) {
        if (!this.isGtagInjected) {
            // Google Tag Manager
            window.dataLayer = window.dataLayer || [];
            const gtag = function () {
                window.dataLayer.push(arguments);
            };
            //@ts-ignore-line
            gtag('js', new Date());
            //@ts-ignore-line
            gtag('config', measurementId);
            //G-XXXXXXXX
            const gtagScript = document.createElement('script');
            gtagScript.async = true;
            gtagScript.id = this.gtagScriptId;
            gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
            document.body.appendChild(gtagScript);
            this.isGtagInjected = true;
        }
    }
    injectTargetingScript(measurementId: string) {
        if (!this.isGtagInjected) {
            // Google Tag Manager

            window.dataLayer = window.dataLayer || [];
            const gtag = function () {
                window.dataLayer.push(arguments);
            };
            //@ts-ignore-line
            gtag('js', new Date());
            //@ts-ignore-line
            gtag('config', measurementId);
            //G-XXXXXXXX
            const gtagScript = document.createElement('script');
            gtagScript.async = true;
            gtagScript.id = this.gtagScriptId;
            gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
            document.body.appendChild(gtagScript);
            this.isGtagInjected = true;
        }
    }
    removeScripts() {
        const gtagScript = document.getElementById(this.gtagScriptId);
        if (gtagScript) gtagScript.remove();
    }

    removePersistedCookies(blackList: string[]) {
        const cookies = document.cookie.split(';');
        const rootDomain = this.getRootDomain();
        for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie.trim().split('=');
            for (const listKey of blackList) {
                if (cookieKey.includes(listKey)) {
                    if (rootDomain) document.cookie = `${cookieKey}=;path=/; domain=${rootDomain}; path=/; expires=${new Date(0).toUTCString()};`;
                }

            }
        }
    }
    getCookie(cookieName) {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i].trim();
            if (cookie.includes(name)) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return null;
    }
}
