<c-navbar></c-navbar>
<section class="tos">
    <h1>{{'Tos.Title' | translate}}</h1>
    <p [innerHTML]="'Tos.Subtitle' | translate"></p>
    <!-- General Provisions -->
    <h6 class="tos__numbered">
        1. {{'Tos.GeneralProvisions.Title' | translate}}
    </h6>
    <p>{{'Tos.GeneralProvisions.Text' | translate}}</p>
    <!-- Copyright -->
    <h6 class="tos__numbered">2. {{'Tos.Copyright.Title' | translate }}</h6>
    <p [innerHTML]="'Tos.Copyright.Text' | translate"></p>
    <!-- Responsibility -->
    <h6 class="tos__numbered">3. {{'Tos.Responsibility.Title' | translate}}</h6>
    <p>{{'Tos.Responsibility.Text' | translate}}</p>
    <!-- Technical Provisions -->
    <h6 class="tos__numbered">
        4. {{'Tos.TechnicalProvisions.Title' | translate}}
    </h6>
    <p>{{'Tos.TechnicalProvisions.Text' | translate}}</p>
    <!-- Third Party -->
    <h6 class="tos__numbered">5. {{'Tos.ThirdParty.Title' | translate}}</h6>
    <p>{{'Tos.ThirdParty.Text' | translate}}</p>
    <!-- Operator Details  -->
    <h6 class="tos__numbered">6. {{'Tos.OperatorDetails.Title' | translate}}</h6>
    <div class="tos-details">
        <div>
            <span>{{'Tos.OperatorDetails.Name' | translate}}:</span>
            <span>Rare Crew s.r.o.</span>
        </div>
        <div>
            <span>{{'Tos.OperatorDetails.RegisteredOffice' | translate}}:</span>
            <span>Lazaretská 23, 811 09 Bratislava</span>
        </div>
        <div>
            <span>{{'Tos.OperatorDetails.CompanyRegistration' |
                translate}}:</span>
            <span>46007423</span>
        </div>
        <!-- sk only -->
        <div *ngIf="language === 'sk'">
            <span>DIČ:</span>
            <span>2023177959</span>
        </div>
        <div>
            <span>E-mail:</span>
            <a href="mailto:info@mydropmatters.com">info&#64;mydropmatters.com</a>
        </div>
        <div>
            <span>{{'Tos.OperatorDetails.Website' | translate}}:</span>
            <a href="/">{{'Tos.OperatorDetails.WebUrl' | translate}}</a>
        </div>
    </div>
    <h6 class="tos__subtitle">{{'Tos.Contact.Title' | translate}}</h6>
    <p>
        {{'Tos.Contact.Text_1' | translate}}
        <a href="mailto:info@mydropmatters.com"> info&#64;mydropmatters.com</a>.
        <br />
        {{'Tos.Contact.Text_2' | translate}}
    </p>
</section>
<c-footer></c-footer>
<cookie-banner></cookie-banner>