import {
    Component,
    HostListener,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RouteHandlerService } from '../../services/route-handler-service';
import { SupportedLanguage } from '../../app.component';
import { environment } from 'apps/core/src/environments/environment';

export type Language = 'en' | 'sk';

@Component({
    selector: 'c-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
    constructor(
        private translate: TranslateService,
        private router: Router,
        private rh: RouteHandlerService
    ) { }
    currentLanguage = this.translate.currentLang as Language;
    activeSegment: string = '';
    mobileMenuOpen: boolean = false;
    isTablet: boolean = false;

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((e: LangChangeEvent) => {
            const lang = e.lang;
            if (lang) this.currentLanguage = e.lang as Language;
        });
        this.rh.activeSegment.subscribe((segment) => {
            // special case handled
            if (segment) this.activeSegment = segment;
            if (segment && this.rh.isSlovakOnly(segment)) {
                this.currentLanguage = 'sk';
                this.translate.use('sk');
            }
        });
        this.isTablet = window.innerWidth <= 1024;
    }

    toggleLanguage() {
        const firstSegment = this.router.url.split('/')[1];
        if (this.rh.isSlovakOnly(firstSegment)) { this.returnToEnIndex(); return; }
        const lang = localStorage.getItem('langPref') as SupportedLanguage;
        if (lang === 'en') {
            this.translate.use('sk');
            localStorage.setItem('langPref', 'sk');
            if (environment.production)
                window.location.href = `https://mydropmatters.sk/${firstSegment}`;
            else window.location.href = `https://dev.mydropmatters.sk/${firstSegment}`;
        } else {
            this.translate.use('en');
            localStorage.setItem('langPref', 'en');
            if (environment.production)
                window.location.href = `https://mydropmatters.com/${firstSegment}`;
            else window.location.href = `https://dev.mydropmatters.com/${firstSegment}`;
        }
        if (this.isTablet && lang === 'sk') this.toggleMobileMenu();
    }

    isFloating: boolean = false;
    @HostListener('window:scroll', ['$event']) onScroll() {
        // offset tbd
        this.isFloating = window.scrollY > 100;
    }
    @HostListener('window:resize') onResize() {
        this.isTablet = window.innerWidth <= 1024;
    }

    scrollToDownload(e: Event) {
        e.preventDefault();
        const url = this.router.url;
        if (url === '/') {
            const target = document.getElementById('download');
            target.scrollIntoView();
        } else {
            this.router.navigate([`/`]).then((r) => {
                setTimeout(() => {
                    const target = document.getElementById('download');
                    target.scrollIntoView();
                }, 150);
            });
        }
    }
    returnToEnIndex() {
        localStorage.setItem('langPref', 'en');
        this.translate.use('en');
        if (environment.production) {
            window.location.href = `https://mydropmatters.com`;
        }
        else window.location.href = `https://dev.mydropmatters.com`

    }
    goHome() {
        const url = this.router.url;
        if (url == '/') window.scrollTo(0, 0);
        else this.router.navigate(['/']);
    }
    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }
}
