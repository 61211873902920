<a
    #downloadLink
    [hidden]="true"
    href="assets/download/preskoly.zip"
    download="preskoly.zip"
></a>

<section class="s-other">
    <div class="s-other__inner">
        <img src="assets/images/404/my drop matters_logo.svg" />
        <h1>Vaše súbory boli stiahnuté</h1>
        <span>Prosím skontrolujte si priečinok stiahnuté súbory.</span>
        <button (click)="goHome()">Späť na hlavnú stránku</button>
    </div>
</section>
