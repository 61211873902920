import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../navbar/navbar.component';
import { RouteHandlerService } from '../../services/route-handler-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: 'c-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
    constructor(
        private translate: TranslateService,
        private rh: RouteHandlerService,
        private sanitizer: DomSanitizer
    ) { }

    language: Language = this.translate.currentLang as Language;
    activeSegment: string = '';
    ngOnInit(): void {
        this.translate.onLangChange.subscribe((c) => {
            if (c) this.language = c.lang as Language;
        });

        this.rh.activeSegment.subscribe((segment) => {
            if (segment) this.activeSegment = segment;
        });
    }
    getVideoUrl(): SafeResourceUrl {
        const videoId = this.language === 'sk' ? '1HH1oviPfTU' : 'ZjXLnVk3njg';
        const url = `https://www.youtube.com/watch?v=${videoId}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
